import { FC, ReactNode, useState } from "react"
import { useLocation, useParams } from "react-router-dom"
import { PageNameContext } from "src/contexts/PageNameContext"
import ScreenTemplate from "src/templates/ScreenTemplate"
import ContractLayers from "./ContractLayers"
import ContractHeader from "./ContractHeader"
import ContractProcessedModal from "./ContractProcessedConfirmation"
import * as RD from "@appia/remote-data"
import {
  Button,
  Card,
  DownloadIcon,
  RotateRightIcon,
  Toast,
} from "@appia/ui-components"
import { ContractDetails } from "@appia/api"
import { logButtonClick } from "src/amplitude"
import { generatePDF } from "./GeneratePDF/GeneratePDF"
import { useGetContractDetails, useGetPBQADocuments } from "src/swr"
import ContractModal from "src/components/ContractModal"
import ToastViewport from "src/components/ToastViewport"
import { useUserFullNameById } from "src/components/OwnerSelection"
import Loading from "src/components/Loading"
import ErrorMessage from "src/components/ErrorMessage"
import ContractPreviousVersions from "./ContractPreviousVersions"
import ContractDocuments from "../../components/ContractDocuments"
import { getKiContractData } from "./utils"
import PartnerOnlyWarningModal from "./PartnerOnlyWarningModal/PartnerOnlyWarningModal"

const PAGE_NAME = "View Contract"

const ViewContractScreen: FC = () => {
  const { contractId } = useParams()
  const location = useLocation()
  if (!contractId) throw new Error("Contract ID is not provided in the URL")

  const { request: contractReq } = useGetContractDetails(contractId)

  const [modalOpen, setModalOpen] = useState<boolean>(
    location.state?.fromSubmit || false,
  )
  const [modalOpenPBQA, setModalOpenPBQA] = useState<boolean>(false)
  const [modalOpenPDF, setModalOpenPDF] = useState<boolean>(false)
  const [toastType, setToastType] = useState<Toast.ToastType>("success")
  const [toastMessage, setToastMessage] = useState<ReactNode>(null)
  const toastState = Toast.useToastState()

  const assignedToId = RD.isSuccess(contractReq)
    ? contractReq.data.contract.contract.version.assignedTo
    : null
  const assignedToName = useUserFullNameById(assignedToId)

  const policyId = RD.isSuccess(contractReq)
    ? contractReq.data.contract.contract.version.externalId
    : undefined

  const { isKiOnSlip, kiContractDataOnly } = getKiContractData(contractReq)

  const setAndTriggerToast = (
    toastType: Toast.ToastType,
    toastMessage: ReactNode,
  ): void => {
    setToastType(toastType)
    setToastMessage(toastMessage)
    toastState.triggerToast()
  }

  // The PBQA ID (and document) will be the same across all sections of a Contract, hence we just use the first Section.
  // Fallback to null to conditionally send the documents request only if pbqaId is present.
  // SWR will not send a request if the argument is null.
  const pbqaId =
    (RD.isSuccess(contractReq) &&
      contractReq.data.contract.sections[0]?.pbqaId) ||
    null

  const { request: documentsRequest } = useGetPBQADocuments(pbqaId)

  return (
    <PageNameContext.Provider value={PAGE_NAME}>
      <ScreenTemplate
        pageTitle="Contract processing pipeline"
        layout={{ type: "regular", backPath: "/contract" }}
      >
        {RD.match(
          contractReq,
          <Loading />,
          <Loading />,
          (contractData: ContractDetails) => (
            <>
              <div className="flex items-center justify-between">
                <h3
                  className="text-2xl font-bold"
                  aria-labelledby="header-label"
                >
                  Your Contract
                </h3>
                <div className="flex flex-wrap items-start justify-end gap-4">
                  <Button
                    data-cy="export-contract-pdf"
                    theme="pop"
                    style="filled"
                    label="Download TPU data"
                    icon={{ position: "right", icon: <DownloadIcon /> }}
                    onClick={() => {
                      logButtonClick({
                        buttonName: "Generate PDF",
                        containerName: "Main",
                        pageName: PAGE_NAME,
                      })
                      if (contractData && isKiOnSlip) {
                        generatePDF(
                          // @ts-expect-error - ignoring null
                          kiContractDataOnly.contract,
                          contractData.versions.length,
                        )
                      } else {
                        setModalOpenPDF(true)
                      }
                    }}
                  />
                  <Button
                    data-cy="redo-contract"
                    theme="night"
                    style="outlined"
                    label="Redo"
                    aria-label="Redo"
                    icon={{ position: "right", icon: <RotateRightIcon /> }}
                    onClick={() => {
                      setModalOpenPBQA(true)
                      logButtonClick({
                        buttonName: "Process Contract",
                        containerName: "Main",
                        pageName: PAGE_NAME,
                      })
                    }}
                  />
                </div>
              </div>

              <section>
                <ContractHeader
                  insured={contractData.contract.header.insured}
                  brokingHouse={contractData.contract.header.brokingHouse}
                  uniqueMarketReference={
                    contractData.contract.contract.uniqueMarketReference
                  }
                  programmeReference={
                    contractData.contract.sections[0]?.lines[0]?.syndicateData
                      .syndicateData.britProgrammeReference
                  }
                  assignedTo={assignedToName}
                  versionStatus={
                    contractData.contract.contract.version.versionStatus
                  }
                />
              </section>

              <section className="pt-4" aria-labelledby="layers-label">
                <h3 id="layers-label" className="mb-2 text-xl">
                  Layers
                </h3>
                <ContractLayers contractData={contractData.contract} />
              </section>

              <ContractDocuments
                documentsRequest={documentsRequest}
                pageName={PAGE_NAME}
              />

              <ContractPreviousVersions
                hasKiOnSlip={isKiOnSlip}
                contractData={contractData}
                openPDFModal={() => setModalOpenPDF(true)}
              />

              <Toast.Toast
                type={toastType}
                message={toastMessage}
                open={toastState.open}
                onOpenChange={toastState.onOpenChange}
              />

              <ToastViewport />
              {modalOpen && (
                <ContractProcessedModal
                  isOpen={modalOpen}
                  onClose={() => setModalOpen(false)}
                />
              )}

              {modalOpenPBQA &&
                (modalOpenPBQA && policyId ? (
                  <ContractModal
                    isOpen={modalOpenPBQA}
                    onClose={() => setModalOpenPBQA(false)}
                    onSuccess={() => setModalOpenPBQA(false)}
                    contractPolicyId={policyId}
                    triggerToast={setAndTriggerToast}
                  />
                ) : (
                  <Card>
                    <ErrorMessage message="No Policy ID Found. Failed to start Contract Process" />
                  </Card>
                ))}
              {modalOpenPDF && (
                <PartnerOnlyWarningModal
                  isOpen={modalOpenPDF}
                  onClose={() => setModalOpenPDF(false)}
                />
              )}
            </>
          ),
          contractError => (
            <ErrorMessage
              message="Failed to load Contract"
              error={contractError}
            />
          ),
        )}
      </ScreenTemplate>
    </PageNameContext.Provider>
  )
}

export default ViewContractScreen
