import {
  EnergySectionFormField,
  LayerFormField,
  SectionFormField,
} from "./types"
import { parseGroupClass, parseMinorClass, parseSubClass } from "../parsers"
import {
  ContractResponse,
  ContractSectionItem,
  EnergyReferenceSplit,
  KiSyndicateData,
  MinorClassCode,
  ReferenceSplit,
  SubClassCode,
  SyndicateLine,
} from "@appia/api"
import { CreateSectionFormField } from "./sectionFormUtils"
import { EnergyGroupClassCode } from "@appia/api/src/Contracts/EnergyGroupClass"

const isEditableSyndicate = (syndicateId: number): boolean =>
  syndicateId === 1618

const getKiSyndicateLineOrDefaultFirst = (
  lines: SyndicateLine[],
): SyndicateLine => {
  const syndicateLine = lines.find(line => line.nominatedSyndicateId === 1618)
  return syndicateLine || lines[0]
}

export const initializeLayerFormFields = (
  sections: ContractSectionItem[],
): Record<number, LayerFormField> => {
  return sections.reduce(
    (acc: Record<number, LayerFormField>, section: ContractSectionItem) => {
      const initializeSyndicateLine = getKiSyndicateLineOrDefaultFirst(
        section.lines,
      )
      const syndicateData = initializeSyndicateLine.syndicateData.syndicateData

      const layerFormField: LayerFormField = {
        layerId: section.sectionId,
        minorClass: {
          raw: syndicateData.minorClass,
          validated: parseMinorClass(syndicateData.minorClass),
          showError: false,
        },
        subClass: {
          raw: syndicateData.subClass,
          validated: parseSubClass(syndicateData.subClass),
          showError: false,
        },
        brokerSelectedIndustry: section.brokerSelectedIndustry,
        sumTotalAllocatedPremiumCheck: 0,
        yoa: section.yoa,
        groupClass: syndicateData.groupClass || "N/A",
        producingTeam: syndicateData.producingTeam || "N/A",
        classType: syndicateData.classType || "N/A",
        majorClass: syndicateData.majorClass || "N/A",
        sections: initializeSections(
          [initializeSyndicateLine],
          section.limitAmount,
        ),
      }

      return { ...acc, [section.sectionId]: layerFormField }
    },
    {},
  )
}

export const initializeSections = (
  lines: SyndicateLine[],
  limitAmount: number,
): Record<number, SectionFormField> => {
  return lines.reduce((sectionsAcc, line) => {
    const referenceSplits =
      line.syndicateData.syndicateData.referenceSplit || []
    const isSingleReferenceSplit = referenceSplits.length === 1

    const lineSections = referenceSplits.reduce<{
      [key: number]: SectionFormField
    }>((refAcc, refSplit, refIndex) => {
      const isEnergyLayer =
        line.syndicateData.syndicateData.groupClass?.startsWith("energy")

      if (isEnergyLayer) {
        const energyRefSplit = refSplit as EnergyReferenceSplit

        const sectionFormField = CreateSectionFormField(
          limitAmount,
          line,
          energyRefSplit,
          isSingleReferenceSplit
            ? line.writtenPremium
            : refSplit.premiumAllocation,
          isSingleReferenceSplit
            ? line.writtenPremiumCurrency
            : refSplit.premiumAllocationCurrency,
          isSingleReferenceSplit ? 100 : refSplit.premiumAllocationPercent,
          true,
          {
            limitAllocation: isSingleReferenceSplit
              ? Number(limitAmount)
              : energyRefSplit.limitAllocation,
            limitPercent: isSingleReferenceSplit
              ? 100
              : energyRefSplit.limitAllocationPercent,
            minorClass: {
              raw: energyRefSplit.minorClass,
              validated: parseMinorClass(energyRefSplit.minorClass),
              showError: false,
            },
            subClass: {
              raw: energyRefSplit.subClass,
              validated: parseSubClass(energyRefSplit.subClass),
              showError: false,
            },
            groupClass: {
              raw: energyRefSplit.groupClass,
              validated: parseGroupClass(energyRefSplit.groupClass),
              showError: false,
            },
          },
        )
        return { ...refAcc, [refIndex]: sectionFormField }
      } else {
        const sectionFormField = CreateSectionFormField(
          limitAmount,
          line,
          refSplit,
          isSingleReferenceSplit
            ? line.writtenPremium
            : refSplit.premiumAllocation,
          isSingleReferenceSplit
            ? line.writtenPremiumCurrency
            : refSplit.premiumAllocationCurrency,
          isSingleReferenceSplit ? 100 : refSplit.premiumAllocationPercent,
          false,
        )
        return { ...refAcc, [refIndex]: sectionFormField }
      }
    }, {})

    return { ...sectionsAcc, ...lineSections }
  }, {})
}

export const updateContractDataWithFormFields = (
  contractData: ContractResponse,
  layerFormFields: Record<number, LayerFormField>,
): ContractSectionItem[] => {
  const updatedContractData: ContractResponse = JSON.parse(
    JSON.stringify(contractData),
  )

  updatedContractData.sections.forEach(section => {
    const formFieldsForSection = layerFormFields[section.sectionId]

    if (formFieldsForSection) {
      section.lines.forEach(line => {
        updateSyndicateData(
          line.syndicateData.syndicateData,
          formFieldsForSection,
          line.nominatedSyndicateId,
        )
      })
    }
  })

  return updatedContractData.sections
}

const updateSyndicateData = (
  syndicateData: KiSyndicateData,
  formFieldsForSection: LayerFormField,
  nominatedSyndicateId: number,
): void => {
  syndicateData.minorClass = formFieldsForSection.minorClass
    .raw as MinorClassCode
  syndicateData.subClass = formFieldsForSection.subClass.raw as SubClassCode

  if (isEditableSyndicate(nominatedSyndicateId)) {
    Object.entries(formFieldsForSection.sections).forEach(
      ([index, formSection]) => {
        const refSplit = syndicateData.referenceSplit?.[Number(index)]
        if (refSplit) {
          updateReferenceSplit(refSplit, formSection)
        }
      },
    )
  }
}

const updateReferenceSplit = (
  refSplit: ReferenceSplit,
  formSection: SectionFormField | EnergyReferenceSplit,
): void => {
  const {
    policyRef,
    eea,
    premiumAllocationValueAndCurrency,
    premiumAllocationPercent,
  } = formSection as SectionFormField

  refSplit.policyReference = policyRef.raw
  refSplit.eea = eea ? eea.raw : false
  refSplit.premiumAllocation = premiumAllocationValueAndCurrency.raw.amount
  refSplit.premiumAllocationCurrency =
    premiumAllocationValueAndCurrency.raw.unit
  refSplit.premiumAllocationPercent = premiumAllocationPercent.raw

  if (
    isEnergyReferenceSplit(refSplit) &&
    isEnergySectionFormField(formSection)
  ) {
    Object.assign(refSplit, {
      groupClass: formSection.groupClass.raw as EnergyGroupClassCode,
      limitAllocation: formSection.limitAllocationValueAndCurrency.raw.amount,
      limitAllocationCurrency:
        formSection.limitAllocationValueAndCurrency.raw.unit,
      limitAllocationPercent: formSection.limitAllocationPercent.raw,
      subClass: formSection.subClass.raw as SubClassCode,
      minorClass: formSection.minorClass.raw as MinorClassCode,
    })
  }
}

// Helper type guard for EnergySectionFormField
const isEnergySectionFormField = (
  formSection: SectionFormField | EnergyReferenceSplit,
): formSection is EnergySectionFormField => {
  return (
    "groupClass" in formSection &&
    "limitAllocationValueAndCurrency" in formSection &&
    "limitAllocationPercent" in formSection &&
    "subClass" in formSection &&
    "minorClass" in formSection
  )
}

const isEnergyReferenceSplit = (
  refSplit: ReferenceSplit | EnergyReferenceSplit,
): refSplit is EnergyReferenceSplit => {
  return (
    (refSplit as EnergyReferenceSplit).groupClass !== undefined ||
    (refSplit as EnergyReferenceSplit).limitAllocation !== undefined
  )
}
