import { Card } from "@appia/ui-components"
import { ReactElement } from "react"
import DefinitionList from "src/components/DefinitionList"
import { ContractHeaderItem, ContractItem, KiSyndicateData } from "@appia/api"

export interface ContractHeaderDataProps {
  insured: ContractHeaderItem["insured"]
  brokingHouse: ContractHeaderItem["brokingHouse"]
  uniqueMarketReference: ContractItem["uniqueMarketReference"]
  programmeReference: KiSyndicateData["britProgrammeReference"]
  assignedTo: ContractItem["version"]["assignedTo"]
  versionStatus: ContractItem["version"]["versionStatus"]
}

const ContractHeader = ({
  insured,
  brokingHouse,
  uniqueMarketReference,
  programmeReference,
  assignedTo,
  versionStatus,
}: ContractHeaderDataProps): ReactElement => {
  return (
    <Card className="mt-6">
      <DefinitionList
        items={[
          {
            label: "Insured",
            value: insured,
          },
          {
            label: "Broking House",
            value: brokingHouse,
          },
          { label: "UMR", value: uniqueMarketReference },
          {
            label: "Programme Reference",
            value: programmeReference,
          },
          {
            label: "Owner",
            value: assignedTo,
          },
          { label: "Status", value: versionStatus },
        ]}
      />
    </Card>
  )
}

export default ContractHeader
