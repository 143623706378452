import * as Sentry from "@sentry/react"
import {
  EnergyReferenceSplit,
  MinorClassCode,
  MinorSubClassesRequest,
  ReferenceSplit,
  SyndicateLine,
} from "@appia/api"
import {
  parseEeaOrRow,
  parseGroupClass,
  parseMinorClass,
  parsePolicyReference,
  parseSectionPercentage,
  parseSectionValueAndCurrency,
  parseSubClass,
} from "../parsers"

import {
  BaseFormField,
  EnergySectionFormField,
  LayerFormField,
  SectionFormField,
} from "./types"
import { FormField } from "@appia/form-data"
import { isValidPremium } from "./premiumUtils"

export function CreateSectionFormField(
  limitAmount: number,
  line: SyndicateLine,
  refSplit: ReferenceSplit | EnergyReferenceSplit,
  premiumAllocationAmount: number | null,
  premiumAllocationCurrencyValue: string | null,
  premiumAllocationPercentValue: number | null,
  isEnergy: boolean,
  energyFields?: {
    limitAllocation: number | null
    limitPercent: number | null
    minorClass: FormField<MinorClassCode, string> & { showError: boolean }
    subClass: FormField<string, string | null> & { showError: boolean }
    groupClass: FormField<string, string | null> & { showError: boolean }
  },
): BaseFormField {
  const baseSectionFormField: SectionFormField = {
    layerWrittenPremium: line.writtenPremium,
    policyRef: {
      raw: refSplit.policyReference,
      validated: parsePolicyReference(refSplit.policyReference),
      showError: false,
    },
    eea: {
      raw: refSplit.eea,
      validated: parseEeaOrRow(refSplit.eea),
      showError: false,
    },
    premiumAllocationValueAndCurrency: {
      raw: {
        amount: premiumAllocationAmount,
        unit: premiumAllocationCurrencyValue,
      },
      validated: parseSectionValueAndCurrency({
        amount: premiumAllocationAmount,
        unit: premiumAllocationCurrencyValue,
      }),
      showError: false,
    },
    premiumAllocationPercent: {
      raw: premiumAllocationPercentValue,
      validated: parseSectionPercentage(premiumAllocationPercentValue),
      showError: false,
    },
  }

  // Handle energy sections
  if (isEnergy && energyFields) {
    return {
      ...baseSectionFormField,
      layerLimit: Number(limitAmount),
      limitAllocationValueAndCurrency: {
        raw: {
          amount: energyFields.limitAllocation,
          unit: premiumAllocationCurrencyValue,
        },
        validated: parseSectionValueAndCurrency({
          amount: energyFields.limitAllocation,
          unit: premiumAllocationCurrencyValue,
        }),
        showError: false,
      },
      limitAllocationPercent: {
        raw: energyFields.limitPercent,
        validated: parseSectionPercentage(energyFields.limitPercent),
        showError: false,
      },
      minorClass: {
        raw: energyFields.minorClass.raw,
        validated: parseMinorClass(energyFields.minorClass.raw),
        showError: false,
      },
      subClass: {
        raw: energyFields.subClass.raw,
        validated: parseSubClass(energyFields.subClass.raw),
        showError: false,
      },
      groupClass: {
        raw: energyFields.groupClass.raw,
        validated: parseGroupClass(energyFields.groupClass.raw),
        showError: false,
      },
    } as EnergySectionFormField
  }

  return {
    ...baseSectionFormField,
  }
}

export const validateClassesRequest = (
  request: MinorSubClassesRequest,
): MinorSubClassesRequest => {
  const missingFields: string[] = []

  if (!request.groupClass) missingFields.push("Group Class")
  if (!request.producingTeam) missingFields.push("Producing Team")
  if (!request.classType) missingFields.push("Class Type")
  if (!request.majorClass) missingFields.push("Major Class")

  if (missingFields.length > 0) {
    const error = new Error(
      `Missing fields for Minor Subclasses Request: ${missingFields.join(
        ", ",
      )}`,
    )
    Sentry.captureException(error)
    throw error
  }

  return request
}

interface ProcessSectionsParams {
  sections: Record<string, SectionFormField>
  totalAllocatedPremium: number
  sectionCountCheck: boolean
  isTotalAllocatedPremiumValid: boolean
  validateSectionFormField: (
    sectionFormField: SectionFormField,
    totalAllocatedPremium: number,
    sectionCountCheck: boolean,
    isTotalAllocatedPremiumValid: boolean,
  ) => SectionFormField
}

export const processSections = ({
  sections,
  totalAllocatedPremium,
  sectionCountCheck,
  isTotalAllocatedPremiumValid,
  validateSectionFormField,
}: ProcessSectionsParams): Record<string, SectionFormField> => {
  const newSectionsFormFields: Record<string, SectionFormField> = {}

  for (const [sectionId, sectionFormField] of Object.entries(sections)) {
    newSectionsFormFields[sectionId] = validateSectionFormField(
      sectionFormField,
      totalAllocatedPremium,
      sectionCountCheck,
      isTotalAllocatedPremiumValid,
    )
  }

  return newSectionsFormFields
}

interface ProcessLayerParams {
  layerFormField: LayerFormField
  totalAllocatedPremium: number
  totalAllocatedPremiumArray: number[]
  validateSectionFormField: (
    sectionFormField: SectionFormField,
    totalAllocatedPremium: number,
    sectionCountCheck: boolean,
    isTotalAllocatedPremiumValid: boolean,
  ) => SectionFormField
}

export const processLayer = ({
  layerFormField,
  totalAllocatedPremium,
  totalAllocatedPremiumArray,
  validateSectionFormField,
}: ProcessLayerParams): LayerFormField => {
  const sectionLayerWrittenPremium =
    layerFormField.sections[0].layerWrittenPremium ?? 0

  const isTotalAllocatedPremiumValid = isValidPremium(
    totalAllocatedPremium,
    sectionLayerWrittenPremium,
  )

  const sectionCountCheck = isValidSectionCount(
    layerFormField.sections,
    totalAllocatedPremiumArray,
  )

  const newSectionsFormFields = processSections({
    sections: layerFormField.sections,
    totalAllocatedPremium,
    sectionCountCheck,
    isTotalAllocatedPremiumValid,
    validateSectionFormField,
  })

  return {
    ...layerFormField,
    minorClass: {
      ...layerFormField.minorClass,
      showError: !layerFormField.minorClass.validated.valid,
    },
    subClass: {
      ...layerFormField.subClass,
      showError: !layerFormField.subClass.validated.valid,
    },
    sections: newSectionsFormFields,
    sumTotalAllocatedPremiumCheck: totalAllocatedPremium,
  }
}

export const updateLayerErrors = (
  newLayerFormFields: Record<string, LayerFormField>,
  layerIdsWithError: Set<number>,
  isLayerValid: (layer: LayerFormField) => boolean,
): { hasErrors: boolean; updatedLayerIdsWithError: Set<number> } => {
  const newLayerIdsWithError = new Set(layerIdsWithError)
  let hasErrors = false

  for (const layer of Object.values(newLayerFormFields)) {
    if (isLayerValid(layer)) {
      newLayerIdsWithError.delete(layer.layerId)
    } else {
      newLayerIdsWithError.add(layer.layerId)
      hasErrors = true
    }
  }

  return { updatedLayerIdsWithError: newLayerIdsWithError, hasErrors }
}

export const isValidSectionCount = (
  sections: Record<string, SectionFormField>,
  totalAllocatedPremiumArray: number[],
): boolean => Object.keys(sections).length === totalAllocatedPremiumArray.length
