import { FC } from "react"
import { Card } from "@appia/ui-components"
import DefinitionList, { DefinitionItem } from "src/components/DefinitionList"

export interface HeaderSectionProps {
  insured: string
  umr: string
  programmeRef: string
}
const HeaderSection: FC<HeaderSectionProps> = ({
  insured,
  umr,
  programmeRef,
}) => {
  const commonItems: DefinitionItem[] = [
    {
      label: "Insured",
      value: insured,
    },
    {
      label: "UMR",
      value: umr,
    },
    {
      label: "Programme Reference",
      value: programmeRef,
    },
  ]
  return (
    <section className="w-full" aria-label="Header Section">
      <Card className="flex flex-wrap items-start justify-between gap-2">
        <DefinitionList items={commonItems} />
      </Card>
    </section>
  )
}

export default HeaderSection
