import classNames from "classnames"
import { FC } from "react"
import EmptyData from "./EmptyData"

export interface DefinitionItem {
  label: string
  value: string | null | undefined
}

const DefinitionList: FC<{
  items: DefinitionItem[]
  className?: string
  itemClassName?: string
}> = ({ items, className, itemClassName }) => (
  <dl
    className={classNames(
      "flex w-full flex-wrap items-start justify-between gap-8",
      className,
    )}
  >
    {items.map((item, i) => (
      <div key={i} className={classNames(itemClassName)}>
        <dt className="mb-2 font-bold">{item.label}</dt>
        <dd>{item.value ?? <EmptyData />}</dd>
      </div>
    ))}
  </dl>
)

export default DefinitionList
