import { ApiError, ContractDetails } from "@appia/api"
import * as RD from "@appia/remote-data"

export const hasKiLines = (contractDetails: ContractDetails): boolean =>
  contractDetails.contract.sections.some(section =>
    section.lines.some(line => line.nominatedSyndicateId === 1618),
  )

export const filterKiLines = (
  contractDetails: ContractDetails,
): ContractDetails => {
  const filteredSections = contractDetails.contract.sections.map(section => ({
    ...section,
    lines: section.lines.filter(line => line.nominatedSyndicateId === 1618),
  }))

  return {
    ...contractDetails,
    contract: {
      ...contractDetails.contract,
      sections: filteredSections,
    },
  }
}

export const getKiContractData = (
  contractReq: RD.RemoteData<ApiError, ContractDetails>,
): { isKiOnSlip: boolean; kiContractDataOnly: ContractDetails | null } => {
  if (!RD.isSuccess(contractReq)) {
    return { isKiOnSlip: false, kiContractDataOnly: null }
  }

  const isKiOnSlip = hasKiLines(contractReq.data)
  const kiContractDataOnly = isKiOnSlip ? filterKiLines(contractReq.data) : null

  return { isKiOnSlip, kiContractDataOnly }
}
