import axios, { AxiosInstance, AxiosPromise } from "axios"

export enum FileType {
  SIGNED_CONTRACT = "SIGNED_CONTRACT",
  SOV = "SOV",
}

export enum PlacingPlatformSource {
  PPL = "PPL",
  WHITESPACE = "WHITESPACE",
  OTHER = "OTHER",
}

export interface PreUploadFileRequest {
  type: FileType
  umr: string
  source: PlacingPlatformSource
  external_id: string
  file_name: string
  content_type: string
}

export interface PreUploadFileResponse {
  file_id: string
  upload_url: string
}

export interface ConfirmFileUploadRequest {
  file_id: string
}

export const generatePreUploadUrl = (
  client: AxiosInstance,
  body: PreUploadFileRequest,
): AxiosPromise<PreUploadFileResponse> =>
  client.post("/api/files/pre-upload", body, {
    transformResponse: axios.defaults.transformResponse,
  })

export const confirmFileUpload = (
  client: AxiosInstance,
  body: ConfirmFileUploadRequest,
): AxiosPromise => client.post("/api/files/confirm-upload", body)
