import {
  ENDORSEMENT_UPLOAD_TYPE_NOTICE,
  ENDORSEMENT_UPLOAD_TYPE_REQUEST,
  EndorsementUploadType,
  SOURCE_UPLOAD_TYPE_PPL,
  SOURCE_UPLOAD_TYPE_WHITESPACE,
  SourceUploadType,
} from "@appia/api"
import { ValidatedData, mkInvalidData, mkValidData } from "@appia/form-data"

export const INVALID_FIELD_ERROR_MESSAGE = "Please fill in this field."

const parseUmr = (value: string): ValidatedData<string> => {
  if (value.length !== 12 || value.includes(" ")) {
    return mkInvalidData(INVALID_FIELD_ERROR_MESSAGE)
  }

  return mkValidData(value)
}

const parseInsuredName = (value: string): ValidatedData<string> => {
  if (value.replace(/\s+/g, "").length === 0) {
    return mkInvalidData(INVALID_FIELD_ERROR_MESSAGE)
  }

  return mkValidData(value)
}

const isEndorsementType = (value: unknown): value is EndorsementUploadType =>
  value === ENDORSEMENT_UPLOAD_TYPE_REQUEST ||
  value === ENDORSEMENT_UPLOAD_TYPE_NOTICE

const parseEndorsementType = (
  value: string | null,
): ValidatedData<EndorsementUploadType> =>
  isEndorsementType(value)
    ? mkValidData(value)
    : mkInvalidData(INVALID_FIELD_ERROR_MESSAGE)

const isSourceUploadType = (value: unknown): value is SourceUploadType =>
  value === SOURCE_UPLOAD_TYPE_PPL || value === SOURCE_UPLOAD_TYPE_WHITESPACE

const parseSourceUploadType = (
  value: string | null,
): ValidatedData<SourceUploadType> =>
  isSourceUploadType(value)
    ? mkValidData(value)
    : mkInvalidData(INVALID_FIELD_ERROR_MESSAGE)

export {
  parseUmr,
  parseInsuredName,
  parseEndorsementType,
  parseSourceUploadType,
}
