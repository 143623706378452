import React, { useState } from "react"
import { Button } from "@appia/ui-components"
import { DocumentsUpload } from "./DocumentsUpload"

const DocumentsScreen: React.FC = (): JSX.Element => {
  const [showUploadArea, setShowUploadArea] = useState(false)

  const toggleUploadArea = (): void => {
    setShowUploadArea(!showUploadArea)
  }

  return (
    <div className="pt-4">
      <Button
        label="UPLOAD"
        style="filled"
        theme="pop"
        onClick={toggleUploadArea}
      />

      {showUploadArea && <DocumentsUpload />}
    </div>
  )
}

export default DocumentsScreen
