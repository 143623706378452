import { Card } from "@appia/ui-components"
import { FC, ReactElement } from "react"
import DefinitionList from "src/components/DefinitionList"
import { ReferenceSplit } from "@appia/api"
import { formatCurrency, formatPercentage2DP } from "src/utils/prettyPrinters"

interface LayerSectionProps {
  sections: ReferenceSplit[]
}

const SectionDetails: FC<LayerSectionProps> = ({ sections }): ReactElement => {
  return (
    <>
      {sections.map((section, index) => {
        return (
          <div key={index}>
            <h4 className="text-l mx-4 mb-2 mt-4 font-bold">
              {`Section ${index + 1} (${section.eea ? "EEA" : "ROW"})`}
            </h4>
            <Card className="mx-4">
              <DefinitionList
                className="grid grid-cols-3 gap-x-2 gap-y-1"
                items={[
                  {
                    label: "Policy reference",
                    value: section.policyReference,
                  },
                  {
                    label: "EEA or ROW",
                    value: section.eea ? "EEA" : "ROW",
                  },
                  {
                    label: "Premium allocation",
                    value:
                      section.premiumAllocationCurrency !== null &&
                      section.premiumAllocation !== null
                        ? formatCurrency(
                            section.premiumAllocation,
                            section.premiumAllocationCurrency,
                          )
                        : "N/A",
                  },
                  {
                    label: "Model price",
                    value:
                      section.ggModelPrice &&
                      section.premiumAllocationCurrency !== null
                        ? formatCurrency(
                            section.ggModelPrice,
                            section.premiumAllocationCurrency,
                          )
                        : "N/A",
                  },
                  {
                    label: "Technical price",
                    value:
                      section.ggTechnicalPrice &&
                      section.premiumAllocationCurrency !== null
                        ? formatCurrency(
                            section.ggTechnicalPrice,
                            section.premiumAllocationCurrency,
                          )
                        : "N/A",
                  },
                  {
                    label: "PLR %",
                    value:
                      section.gnTechnicalPlr !== null
                        ? formatPercentage2DP(section.gnTechnicalPlr)
                        : "N/A",
                  },
                ]}
              />
            </Card>
          </div>
        )
      })}
    </>
  )
}

export default SectionDetails
