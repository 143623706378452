import React, { FC, useId, useState } from "react"
import { DragDrop } from "@uppy/react"
import * as Sentry from "@sentry/react"
import { ErrorMessage, FileUploadArea } from "@appia/ui-components"

import {
  Failure,
  Loading,
  NotAsked,
  RemoteData,
  isFailure,
  isLoading,
  match,
} from "@appia/remote-data"

import {
  ConfirmFileUploadRequest,
  FileType,
  PlacingPlatformSource,
  PreUploadFileRequest,
  confirmFileUpload,
  generatePreUploadUrl,
} from "@appia/api"

import { SuccessResponse } from "@uppy/core"

import useUppy from "@appia/file-upload"
import { StorageProvider } from "@appia/file-upload/src/storageProvider"
import useApiClient from "src/contexts/ApiClientContext"
import * as RD from "@appia/remote-data"

import SuccessModal from "./SuccessModal"
import "../../../components/UploadDocument/UploadArea.css"

import {
  logContractDocumentUploadComplete,
  logContractDocumentUploadStart,
} from "../../../amplitude"

import ProgressBar from "../../../components/UploadDocument/ProgressBar"

import { ACCEPTED_FILE_TYPES, FILETYPE_MSG } from "./acceptedFileTypes"
import { ERROR_MESSAGES } from "./errorMessages"

const PROGRESS_ID = "upload-progress"

export const DocumentsUpload: FC = () => {
  const [uploadRequest, setUploadRequest] =
    useState<RemoteData<Error, SuccessResponse>>(NotAsked)
  const [uploadProgress, setUploadProgress] = useState<number>(0)
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false)

  const apiClient = useApiClient()

  const uppy = useUppy({
    maxMegabytes: 50,
    acceptedDocumentTypes: ACCEPTED_FILE_TYPES,
    provider: StorageProvider.GCS,
    getUploadParameters: async file => {
      if (!file.type) {
        Sentry.captureException(new Error(ERROR_MESSAGES.fileTypeUnrecognised))
        throw new Error(ERROR_MESSAGES.fileTypeUnrecognised)
      }

      const preUploadRequest: PreUploadFileRequest = {
        type: FileType.SIGNED_CONTRACT,
        umr: "TESTUPLOAD123",
        source: PlacingPlatformSource.WHITESPACE,
        external_id: "123e4567-e89b-12d3-a456-426614174000",
        file_name: file.name ?? "UNKNOWN_FILE_NAME",
        content_type: file.type,
      }

      logContractDocumentUploadStart({
        fileId: file.id,
        umr: preUploadRequest.umr,
        type: preUploadRequest.type,
        externalId: preUploadRequest.external_id,
        mimetype: file.type,
      })

      const response = await generatePreUploadUrl(apiClient, preUploadRequest)

      if (response.status !== 200) {
        Sentry.captureException(
          new Error(
            ERROR_MESSAGES.serverUploadError(
              response.status,
              response.statusText,
              preUploadRequest.umr,
              preUploadRequest.file_name,
            ),
          ),
        )
        throw new Error(
          ERROR_MESSAGES.serverUploadError(
            response.status,
            response.statusText,
            preUploadRequest.umr,
            preUploadRequest.file_name,
          ),
        )
      }

      const { upload_url, file_id } = response.data
      if (!upload_url) {
        Sentry.captureException(
          new Error(
            ERROR_MESSAGES.missingUploadUrl(
              preUploadRequest.umr,
              preUploadRequest.file_name,
            ),
          ),
        )
        throw new Error(ERROR_MESSAGES.genericError)
      }

      if (!file_id) {
        Sentry.captureException(
          new Error(
            ERROR_MESSAGES.missingFileId(
              preUploadRequest.umr,
              preUploadRequest.file_name,
            ),
          ),
        )
        throw new Error(ERROR_MESSAGES.genericError)
      }

      uppy.setFileMeta(file.id, {
        fileId: file_id,
        umr: preUploadRequest.umr,
        fileName: preUploadRequest.file_name,
      })

      return {
        url: upload_url,
        method: "PUT",
        headers: {
          "Content-Type": file.type || "application/octet-stream",
        },
      }
    },
    onStartLoad: () => setUploadRequest(Loading),
    onProgress: setUploadProgress,
    onError: async uppyError => {
      Sentry.captureException(uppyError.error)
      setUploadRequest(Failure(new Error(ERROR_MESSAGES.genericError)))
    },
    onSuccess: async (res, file) => {
      if (!file) {
        Sentry.captureException(ERROR_MESSAGES.undefinedFile)
        throw new Error(ERROR_MESSAGES.genericError)
      }

      const fileId = file.meta["fileId"] as string
      const umr = file.meta["umr"] as string
      const fileName = file.meta["fileName"] as string

      if (fileId) {
        const confirmUploadRequest: ConfirmFileUploadRequest = {
          file_id: fileId,
        }
        logContractDocumentUploadComplete({ fileId })
        const response = await confirmFileUpload(
          apiClient,
          confirmUploadRequest,
        )
        if (response.status !== 200) {
          Sentry.captureException(
            new Error(
              ERROR_MESSAGES.serverConfirmError(
                response.status,
                response.statusText,
                umr,
                fileName,
              ),
            ),
          )
          throw new Error(
            ERROR_MESSAGES.serverConfirmError(
              response.status,
              response.statusText,
              umr,
              fileName,
            ),
          )
        }
        setUploadRequest(RD.Success(res))
        setIsSuccessModalOpen(true)
      } else {
        Sentry.captureException(ERROR_MESSAGES.missingFileIdMetadata)
        throw new Error(ERROR_MESSAGES.genericError)
      }
    },
  })

  const loadingId = useId()
  const errorId = useId()

  return (
    <div className="flex h-full flex-col items-center justify-center gap-6">
      <div className="mx-auto w-full max-w-md">
        <div role="alert" className="mx-auto w-full max-w-full bg-white">
          {match(
            uploadRequest,
            null,
            <div className="absolute top-0 left-0 z-[1] flex h-full w-full items-center justify-center bg-otto-grey-100 bg-opacity-75 transition-opacity">
              <ProgressBar id={PROGRESS_ID} progress={uploadProgress} />
            </div>,
            () => (
              <SuccessModal
                isOpen={isSuccessModalOpen}
                onClose={() => {
                  setIsSuccessModalOpen(false)
                  setUploadRequest(NotAsked)
                  setUploadProgress(0)
                }}
              />
            ),
            error => (
              <div className="mb-8">
                <ErrorMessage message={`Upload failed: ${error.message}`} />
              </div>
            ),
          )}
        </div>
        <FileUploadArea
          label={
            <div className="grid gap-2 text-center">
              <p className="text-xl font-bold">Drop your file here</p>
              <p className="text-base">Or browse files from your computer</p>
              <p className="text-base">{FILETYPE_MSG}</p>
            </div>
          }
          input={
            <div className="relative">
              <DragDrop
                uppy={uppy}
                aria-describedby={
                  isLoading(uploadRequest)
                    ? loadingId
                    : isFailure(uploadRequest)
                    ? errorId
                    : undefined
                }
              />
              <div className="absolute top-0 bottom-0 left-0 right-0 cursor-pointer opacity-0"></div>
            </div>
          }
        />
      </div>
    </div>
  )
}

export default DocumentsUpload
