import React from "react"
import ContractHeaderDetailsCard from "./ContractHeaderDetailsCard"
import ContractHeaderDetailsForm from "./ContractHeaderDetailsForm"
import { Navigate, useNavigate, useParams } from "react-router-dom"
import {
  ContractResponse,
  UpdateHeaderData,
  updateContractHeader,
} from "@appia/api"
import * as RD from "@appia/remote-data"
import useApiClient from "src/contexts/ApiClientContext"
import * as Sentry from "@sentry/react"
import { REVIEW_CONTRACT_SECTIONS_PATH } from ".."
import { useGetContract, useGetKiQuote } from "../../../swr"
import { useUserFullNameById } from "../../../components/OwnerSelection"
import Loading from "../../../components/Loading"
import { Card, Toast } from "@appia/ui-components"
import ErrorMessage from "../../../components/ErrorMessage"
import ToastViewport from "src/components/ToastViewport"
import { useToastHandler } from "src/hooks/useToastHandler"
import RarcDefaultWarningBanner from "../RarcDefaultWarningBanner"

const GeneralSectionScreen: React.FC = (): JSX.Element => {
  const navigate = useNavigate()
  const apiClient = useApiClient()
  const { contractId, version } = useParams<{
    contractId: string
    version: string
  }>()

  if (contractId === undefined) {
    throw new Error("Unknown contractId")
  }

  if (version === undefined) {
    throw new Error("Unknown version")
  }

  const versionNumber = parseInt(version)

  if (isNaN(versionNumber)) {
    throw new Error("Unknown version number")
  }

  const { request: contract } = useGetContract(contractId)
  const isValidContract = RD.isSuccess(contract)
  const pbqaId = isValidContract ? contract?.data?.sections?.[0]?.pbqaId : null
  const { request: kiQuoteRequest } = useGetKiQuote(pbqaId)
  const kiQuote = RD.isSuccess(kiQuoteRequest) ? kiQuoteRequest.data : null
  const assignedToId = isValidContract
    ? contract.data.contract.version.assignedTo
    : null
  const assignedToName = useUserFullNameById(assignedToId)

  const { toastType, toastMessage, toastState, setAndTriggerToast } =
    useToastHandler()

  const handleSave = async (newFields: UpdateHeaderData): Promise<void> => {
    try {
      await updateContractHeader(
        apiClient,
        newFields,
        contractId,
        versionNumber,
      )
      setAndTriggerToast("success", "Contract header updated successfully")
    } catch (error) {
      if (error instanceof Error) {
        Sentry.captureException(error)
        setAndTriggerToast("error", "Failed to save")
      }
    }
  }

  const onCancel = (): void => {
    navigate("/contract")
  }

  const onSubmit = async (
    updateHeaderRequest: UpdateHeaderData,
  ): Promise<void> => {
    await handleSave(updateHeaderRequest)
    navigate(`../${REVIEW_CONTRACT_SECTIONS_PATH}`)
  }

  return RD.match(
    contract,
    <Loading className="mt-8" />,
    <Loading className="mt-8" />,
    (contractData: ContractResponse) => {
      const { header } = contractData
      const formFields = {
        brokerName: header.brokerName || "",
        conductRating: header.conductRating || null,
        customerType: header.customerType || null,
      }

      const syndicateData =
        contractData.sections[0]?.lines[0]?.syndicateData.syndicateData
      const headerData = {
        insured: contractData.header.insured,
        brokingHouse: contractData.header.brokingHouse,
        quoteIds: contractData.contract.version.quoteIds,
        umr: contractData.contract.uniqueMarketReference,
        programmeRef: syndicateData.britProgrammeReference || "N/A",
        entity: "1618",
        assignedTo: assignedToName,
        onPlatform: syndicateData.businessType !== "assisted_new_business",
      }

      return (
        <div>
          <RarcDefaultWarningBanner
            createdAt={kiQuote?.createdAt}
            journeyConfigId={
              isValidContract ? contract.data.sections[0].journeyConfigId : null
            }
          />
          <div className="flex items-start justify-center gap-4 p-4">
            <ContractHeaderDetailsCard {...headerData} />
            <ContractHeaderDetailsForm
              onSave={handleSave}
              onCancel={onCancel}
              onSubmit={onSubmit}
              initialFormFields={formFields}
            />
            <Toast.Toast
              type={toastType}
              message={toastMessage}
              open={toastState.open}
              onOpenChange={toastState.onOpenChange}
            />
            <ToastViewport />
          </div>
        </div>
      )
    },
    error => {
      const status = error.response?.status
      return status === 404 ? (
        <Navigate replace to="/404" />
      ) : (
        <Card>
          <ErrorMessage message="Failed to load Contract :" error={error} />
        </Card>
      )
    },
  )
}

export default GeneralSectionScreen
