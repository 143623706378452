import { FC, ReactNode } from "react"
import {
  Card,
  Input,
  InputLabel,
  Select,
  SelectOption,
} from "@appia/ui-components"
import InputWithError from "src/components/InputWithError"
import {
  ENDORSEMENT_UPLOAD_TYPE_NOTICE,
  ENDORSEMENT_UPLOAD_TYPE_REQUEST,
  EndorsementUploadType,
  SOURCE_UPLOAD_TYPE_PPL,
  SOURCE_UPLOAD_TYPE_WHITESPACE,
  SourceUploadType,
} from "@appia/api"
import classNames from "classnames"
import { FormField } from "@appia/form-data"
import { EndorsementUploadFormField } from "./index"

const endorsementTypeSelectOptions: SelectOption[] = [
  { value: ENDORSEMENT_UPLOAD_TYPE_REQUEST, label: "Request" },
  { value: ENDORSEMENT_UPLOAD_TYPE_NOTICE, label: "Notice" },
]

const sourceTypeSelectOptions: SelectOption[] = [
  { value: SOURCE_UPLOAD_TYPE_PPL, label: "PPL" },
  { value: SOURCE_UPLOAD_TYPE_WHITESPACE, label: "Whitespace" },
]

const InputFieldContainer: FC<{
  children: ReactNode
  className?: string
}> = ({ children, className }): JSX.Element => {
  return (
    <div className={classNames("bg-[#f9f8f4] p-4", className)}>
      <div className="w-4/5">{children}</div>
    </div>
  )
}

export interface EndorsementUploadDetailsProps {
  umr: FormField<string, string> & { showError: boolean }
  insuredName: FormField<string, string> & { showError: boolean }
  endorsementType: FormField<EndorsementUploadType, string> & {
    showError: boolean
  }
  source: FormField<SourceUploadType, string> & { showError: boolean }
  onChange: (field: keyof EndorsementUploadFormField, value: string) => void
}

const EndorsementUploadDetails: FC<EndorsementUploadDetailsProps> = ({
  umr,
  insuredName,
  endorsementType,
  source,
  onChange,
}) => {
  return (
    <Card padding={0}>
      <InputLabel label="UMR" className="!mb-0 border-b px-4 py-2 !font-bold">
        <InputFieldContainer>
          <InputWithError
            errorTestId="umr-error-message"
            formField={umr}
            showFormFieldErrors={umr.showError}
            input={errorId => (
              <Input
                hasError={!!errorId}
                id="umr"
                data-testid="umr"
                onChange={e => onChange("umr", e)}
                type="text"
                inputMode="text"
                value={umr.raw}
              />
            )}
          />
        </InputFieldContainer>
      </InputLabel>
      <InputLabel
        label="Insured Name"
        className="!mb-0 border-b px-4 py-2 !font-bold"
      >
        <InputFieldContainer>
          <InputWithError
            errorTestId="insuredName-error-message"
            formField={insuredName}
            showFormFieldErrors={insuredName.showError}
            input={errorId => (
              <Input
                hasError={!!errorId}
                id="insured-name"
                data-testid="insured-name"
                onChange={e => onChange("insuredName", e)}
                type="text"
                inputMode="text"
                value={insuredName.raw}
              />
            )}
          />
        </InputFieldContainer>
      </InputLabel>
      <InputLabel
        label="Endorsement Type"
        className="!mb-0 border-b px-4 py-2 !font-bold"
      >
        <InputFieldContainer>
          <InputWithError
            errorTestId="endorsementType-error-message"
            formField={endorsementType}
            showFormFieldErrors={endorsementType.showError}
            input={errorId => (
              <Select
                aria-label="Endorsement Type"
                errorMessageId={errorId}
                options={endorsementTypeSelectOptions}
                selectedValue={endorsementType.raw}
                onSelect={e => onChange("endorsementType", e)}
                placeholder="Select an endorsement type"
              />
            )}
          />
        </InputFieldContainer>
      </InputLabel>
      <InputLabel
        label="Source"
        className="!mb-0 border-b px-4 py-2 !font-bold"
      >
        <InputFieldContainer>
          <InputWithError
            aria-label="Source"
            errorTestId="source-error-message"
            formField={source}
            showFormFieldErrors={source.showError}
            input={errorId => (
              <Select
                errorMessageId={errorId}
                options={sourceTypeSelectOptions}
                selectedValue={source.raw}
                onSelect={e => onChange("source", e)}
                placeholder="Select a source"
              />
            )}
          />
        </InputFieldContainer>
      </InputLabel>
    </Card>
  )
}

export default EndorsementUploadDetails
