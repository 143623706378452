import axios, { AxiosInstance, AxiosPromise } from "axios"
import { Broker } from "../Broker"
import { EndorsementType } from "./Search"
import { EndorsementStatus } from "./Status"
import { Policy, PolicyReferencesForSyndicate, PolicySource } from "../Policies"

export const ENDORSEMENT_UPLOAD_TYPE_REQUEST = "Request"
export const ENDORSEMENT_UPLOAD_TYPE_NOTICE = "Notice"

export type EndorsementUploadType =
  | typeof ENDORSEMENT_UPLOAD_TYPE_REQUEST
  | typeof ENDORSEMENT_UPLOAD_TYPE_NOTICE

export const SOURCE_UPLOAD_TYPE_PPL = "PPL"
export const SOURCE_UPLOAD_TYPE_WHITESPACE = "Whitespace"

export type SourceUploadType =
  | typeof SOURCE_UPLOAD_TYPE_PPL
  | typeof SOURCE_UPLOAD_TYPE_WHITESPACE

interface Email {
  referralEmail: string
  referralName: string
}

interface Referral {
  body: string
  emails: Email[]
  referredAt: string
  referredBy: string
  subject: string
}

type PolicyData = Pick<
  Policy,
  "inceptionDate" | "policyId" | "policyReference" | "umr"
> & {
  insured: string | null
  source: PolicySource | null
}

export interface Endorsement {
  acceptedBy: string | null
  broker: Broker | null
  completedAt: string | null
  completedBy: string | null
  id: string
  platformUrl: string | null
  policyData: PolicyData | null
  policyId: string | null
  policyReferences: PolicyReferencesForSyndicate[]
  referrals: Referral[]
  rejectedBy: string | null
  rejectionReason: string | null
  status: EndorsementStatus
  teamId: string
  type: EndorsementType
  umr: string | null
}

export const getEndorsementUrl = (endorsement_id: Endorsement["id"]): string =>
  `/api/endorsements/${endorsement_id}`

interface EndorsementCreateRequest {
  name: string
  companyId: string
  teamId: string
  umr: string
  insuredName: string
  endtType: string
  extension: string
  mimetype: string
  size: number
  url: string | null
  source: string
  externalId: string
}

interface EndorsementUploadUrlResponse {
  id: string
  endorsement_id: string
  url: string
  fields: Record<string, string>
}

const toSnakeCase = (str: string): string =>
  str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)

const transformRequest = (
  data: EndorsementCreateRequest,
): Record<string, unknown> => {
  const transformedData: Record<string, unknown> = {}
  for (const key in data) {
    if (Object.prototype.hasOwnProperty.call(data, key)) {
      transformedData[toSnakeCase(key)] =
        data[key as keyof EndorsementCreateRequest]
    }
  }
  return transformedData
}

export const generateEndorsementUploadUrl = (
  client: AxiosInstance,
  body: EndorsementCreateRequest,
): AxiosPromise<EndorsementUploadUrlResponse> =>
  client.post(`/api/v1/endorsements/upload`, transformRequest(body), {
    transformResponse: axios.defaults.transformResponse,
  })
