import { FC, ReactElement, useState } from "react"
import { Tabs } from "@appia/ui-components"
import { ContractResponse, ContractSectionItem } from "@appia/api"
import { formatAttachmentPoint } from "src/utils/formatAttachmentPoint"
import LayerDetails from "./LayerDetails"
import SectionDetails from "./SectionDetails"

interface ContractLayersProps {
  contractData: ContractResponse
}

const ContractLayers: FC<ContractLayersProps> = ({
  contractData,
}): ReactElement => {
  const contractSections: ContractSectionItem[] = contractData.sections
  const [selectedLayerId, setSelectedLayerId] = useState<string>(
    contractSections.length > 0 ? contractSections[0].sectionId.toString() : "",
  )
  const brokerName = contractData.header.brokerName
  const conductRating = contractData.header.conductRating
  const customerType = contractData.header.customerType

  return (
    <Tabs.Root
      asChild
      value={selectedLayerId}
      onValueChange={setSelectedLayerId}
    >
      <div className="overflow-hidden rounded-lg border border-otto-grey-300 bg-white">
        <Tabs.List
          aria-label="Layers"
          className="border-b border-otto-grey-300"
        >
          {contractSections.map(section => (
            <Tabs.Trigger
              key={section.sectionId}
              value={section.sectionId.toString()}
              grow={false}
              className="flex items-center justify-center gap-2 py-3 px-7"
            >
              <span>{formatAttachmentPoint(section)}</span>
            </Tabs.Trigger>
          ))}
        </Tabs.List>

        {contractSections.map(section => (
          <Tabs.Content
            asChild
            key={section.sectionId}
            value={section.sectionId.toString()}
          >
            <div>
              <div className="flex">
                <div className="w-1/2 border-r border-otto-grey-300 pr-2">
                  <span className="block p-4 font-bold">Layer details</span>
                  <hr className="mx-4 border-otto-grey-300" />
                  <LayerDetails
                    layer={section}
                    brokerName={brokerName}
                    conductRating={conductRating}
                    customerType={customerType}
                  />
                </div>
                <div className="w-1/2 pl-2">
                  <span className="block p-4 font-bold">Section details</span>
                  <hr className="mx-4 border-otto-grey-300" />
                  {section.lines[0].syndicateData.syndicateData
                    .referenceSplit && (
                    <SectionDetails
                      sections={
                        section.lines[0].syndicateData.syndicateData
                          .referenceSplit
                      }
                    />
                  )}
                </div>
              </div>
            </div>
          </Tabs.Content>
        ))}
      </div>
    </Tabs.Root>
  )
}

export default ContractLayers
